import React from "react"
import LeftView from "../components/LeftView"
import axios from "axios"
import {useSearchParams} from "react-router-dom"
import {useTranslation} from "react-i18next"

import backgroundPattern from '../images/background.png'
import WarningLottie from "../components/WarningLottie"

export const CamDigiKeyContext = React.createContext(null)

const Loading = () => {
  const { t } = useTranslation()

  return (
    <div className='text-black' style={{backgroundImage: `url(${backgroundPattern})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
      <section>
        <div className="max-w-7xl mx-auto blur-sm">
          <div className="flex justify-center items-center flex-col md:flex-row px-4 py-5" style={{ minHeight: 'calc(100vh - 7rem - 6px)' }}> {/* - 206px for DownloadCamDigiKey else - 6px */ }
            <LeftView />
            {/* <QrCodeView /> */}
          </div>
        </div>

        <div className="fixed inset-0 bg-gray-500 bg-opacity-70 overflow-y-auto h-full w-full flex justify-center items-center" id="loading" style={{ zIndex: 99999 }}>
          <div className="relative mx-2 p-5 border w-96 shadow-lg rounded-md bg-white bg-opacity-70">
            <div className="mt-3 text-center">
              <div className="animate-spin rounded-full h-20 w-20 border-b-2 border-gray-900 mx-auto"></div>
              {/* <h3 className="mt-5 mb-4 text-lg leading-6 font-medium text-gray-900">Logged in Successfully</h3> */}
              <p className="my-5 text-sm text-gray-500">
                <span>{t('app.please_wait')}</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

const Error = ({ error }) => {
  const { t } = useTranslation()

  const buttonGoBackOnClick = () => {
    window?.history.back()
  }

  return (
    <section>
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-center flex-col md:flex-row px-4 py-5" style={{ minHeight: 'calc(100vh - 7rem - 6px)' }}> {/* - 206px for DownloadCamDigiKey else - 6px */ }
          <div className="w-full">
            <div className="mx-auto" style={{ maxWidth: '400px' }}>
              <span className="md:block w-full">
                <WarningLottie />
              </span>
            </div>

            <div className="flex justify-center">
              <div>
                <h1 className="text-center leading-tight font-bold text-gray-700 text-2xl md:text-2xl xl:text-4xl">{t(error?.message)}</h1>
                <p className="text-center text-gray-500 mt-2 mb-8 text-base md:text-lg xl:text-xl">{t(error?.description)}</p>
              </div>
            </div>

            <div className="flex justify-center">
              <button onClick={buttonGoBackOnClick} className="bg-red-500 hover:bg-red-400 text-white py-1 px-4 rounded-md transition duration-100 w-auto" type="button">
                <small>{t('button.go_back')}</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CamDigiKeyProvider = ({ children }) => {
  const [loginToken, setLoginToken] = React.useState(null)
  const [data, setData] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [searchParams] = useSearchParams()

  const requestLogin = React.useCallback(async () => {
    const loginToken = searchParams.get('loginToken')
    if (!loginToken) {
      setLoading(false)
      setError({
        message: 'error.invalid_login_token',
        description: 'error.invalid_login_token_description'
      })
      return
    }

    try {
      const res = await axios.post('/api/v1/authenticate/requestLogin', { loginToken })
      console.log('[REQUEST LOGIN] res =>', res.data)
      const error = res.data?.error
      if (error === 0) {
        setData(res.data.data)
        setLoginToken(loginToken)
        setLoading(false)
      } else if (error === 4000) {
        console.log("[REQUEST LOGIN] Login Token Invalid")
        setLoading(false)
        setError({
          message: 'error.invalid_login_token',
          description: 'error.invalid_login_token_description'
        })
        return
      } else if (error === 4002) {
        console.log("[REQUEST LOGIN] Login Token Expired")
        setLoading(false)
        setError({
          message: 'error.login_token_expired',
          description: 'error.login_token_expired_description'
        })
        return
      } else if (error === 4500) {
        console.log("[REQUEST LOGIN] CAMDIGIKEY SERVER CONNECTION ERROR")
        setLoading(false)
        setError({
          message: 'error.camdigikey_connection',
          description: 'error.camdigikey_connection_description'
        })
        return
      } else {
        console.log("[REQUEST LOGIN] Unknown Error =>", error)
        setLoading(false)
        setError({
          message: 'error.unknown',
          description: 'error.unknown_description'
        })
        return
      }
    } catch (e) {
      console.log("[REQUEST LOGIN] Something Went Wrong =>", e)
      setLoading(false)
      setError({
        message: 'error.something_went_wrong',
        description: 'error.something_went_wrong_description'
      })
      return
    }
  }, [searchParams])

  React.useEffect(() => {
    requestLogin()
  }, [requestLogin])

  return loading
  ? <Loading />
  : ( error
      ? <Error error={error} />
      : <CamDigiKeyContext.Provider value={{ data, setData, loginToken }}>
          {children}
        </CamDigiKeyContext.Provider>
    )
}

export default CamDigiKeyProvider