import React from 'react';
import {useTranslation} from 'react-i18next';
import enFlag from './flag-en.svg';
import khFlag from './flag-kh.svg';

function useOutsideAlerter(ref, expand, setExpand) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (expand) {
          setExpand(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, expand, setExpand]);
}

export default function LanguageSwitcher() {
  const [expand, setExpand] = React.useState(false);
  const { i18n } = useTranslation();
  const wrapperRef = React.useRef(null);

  useOutsideAlerter(wrapperRef, expand, setExpand);

  const langs = {
    en: {
      label: 'English',
      icon: enFlag
    },
    km: {
      label: 'ភាសាខ្មែរ',
      icon: khFlag
    }
  }

  const toggleLanguageDropdown = () => {
    setExpand(!expand);
  }

  const changeLang = (lang) => {
    setExpand(false);
    i18n.changeLanguage(lang);
  }

  return (
    <div ref={wrapperRef} className="flex justify-end">
      <div className="dropdown relative inline-block text-left">
        <button onClick={() => toggleLanguageDropdown()} data-dropdown-toggle="languages" type="button"  id="dropdown-btn" className="inline-flex items-center justify-center w-full rounded-md px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-gray-200" aria-expanded="true" aria-haspopup="true">
          <div className="w-7 h-5 lang-flag-en" style={{ backgroundImage: `url(${langs[i18n.language].icon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }}></div>
        </button>

        <div id="languages" className={(expand ? '' : 'hidden ') + `dropdown-menu origin-top-right absolute right-0 mt-2 w-55 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`} role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
          <div className="pt-1" role="none">
            {
              Object.keys(langs).filter(key => key !== i18n.language).map(key => (
                <button key={key} type="button" onClick={() => changeLang(key)}
                className="change-lang text-gray-700 block w-full text-left px-4 py-2 text-sm"
                  role="menuitem" tabIndex="-1">
                    <span className="inline-flex items-center">
                      <div className="w-7 h-5 mr-2" style={{ backgroundImage: `url(${langs[key].icon})`, backgroundPosition: 'center center', backgroundSize: 'cover' }}></div>
                      <b className="text-gray-700">{' '}{langs[key].label}</b>
                    </span>
                </button>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}