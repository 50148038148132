import {useTranslation} from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer py-2 text-gray-400 mt-5">
      <p className="text-sm text-center text mb-0 copy_right">
        {t("app.footer.copyright")}
      </p>
    </footer>
  );
}