import React, {useContext} from 'react'
import CamDigiKeyProvider, {CamDigiKeyContext} from '../../hooks/useCamDigiKey'
import QrCodeLogin from './QrCodeLogin'
import ConsentLogin from './ConsentLogin'

const AuthenticationProvider = () => {
  const { data, setData } = useContext(CamDigiKeyContext)

  const didLogoutSuccess = (data) => setData(data)
  
  return data?.isAuthenticated ? <ConsentLogin didLogoutSuccess={didLogoutSuccess} /> : <QrCodeLogin />
}

const Login = () => {
  return (
    <CamDigiKeyProvider>
      <AuthenticationProvider />
    </CamDigiKeyProvider>
  )
}

export default Login