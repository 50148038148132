import {useTranslation} from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";

export default function Header ({ urlVerification }) {
  const { t } = useTranslation();

  return (
    <header>
      <div className="w-full text-sm text-center text-gray-500 bg-sky-50 py-4">
        <div className="flex justify-between mx-4 items-center">
          <div>
            {/* just to make url verification tobe centered */}
          </div>
          <div>
            <svg className="mb-1 h-3 w-3 inline text-green-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clipRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" fillRule="evenodd" />
            </svg>
            <span className="url_verification">{" "}{t('app.header.url_verification')}{" "}</span>
            <span className="text-green-600">{urlVerification}</span>
          </div>
          
          {process.env.NODE_ENV !== 'production' ?
          <div>
            <button onClick={() => {
              fetch('http://' + window.location.hostname + ':8000/login-token').then(res => res.json()).then(data => {
                window.location.replace("http://" + window.location.hostname + ":3000/login?loginToken=" + encodeURIComponent(data.data.loginToken))
              })
            }}>
              DEV LOGIN
            </button>
          </div>
          : null}
          
          <LanguageSwitcher />
        </div>
      </div>
    </header>
  );
}