import Header from './components/Header'
import Footer from './components/Footer'

import './App.css';

import backgroundPattern from './images/background.png';
import {Route, Routes} from 'react-router-dom';
import HomePage from './routes/HomePage';
import Login from './routes/Login';
import NotFound from './routes/NotFound';

function App() {
  return (
    <div className='text-black' style={{backgroundImage: `url(${backgroundPattern})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
      <Header urlVerification="https://camdigikey-auth.camdx.gov.kh" />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
