import NotFoundLottie from '../components/NotFoundLottie'
import {useTranslation} from 'react-i18next'

const NotFound = () => {
  const { t } = useTranslation()
  return (
    <section>
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-center items-center flex-col md:flex-row px-4 py-5" style={{ minHeight: 'calc(100vh - 7rem - 6px)' }}> {/* - 206px for DownloadCamDigiKey else - 6px */ }
          <div className="w-full md:pr-16">
            <div className="flex justify-center">
              <div>
                <h1 className="leading-tight font-bold text-gray-700 text-2xl md:text-2xl xl:text-4xl">{t('app.not_found_title')}</h1>
                <p className="text-gray-500 mt-2 mb-8 text-base md:text-lg xl:text-xl">{t('app.not_found_sub_title')}</p>
              </div>
            </div>
            <div className="mx-auto" style={{ width: '400px' }}>
              <span className="md:block w-full">
                <NotFoundLottie />
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NotFound