import Lottie from "react-lottie";
import animation from './animation.json';

export default function QrCodeLottie() {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animation,
      }}
      isClickToPauseDisabled
      ariaRole="label"
    />
  );
}