import ConsentLoginView from "../../components/ConsentLoginView"

export default function ConsentLogin({ didLogoutSuccess }) {
  const setDataAfterLogout = (data) => didLogoutSuccess(data)

  return (
    <section>
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-center items-center flex-col md:flex-row px-4 py-5" style={{ minHeight: 'calc(100vh - 7rem - 6px)' }}> {/* - 206px for DownloadCamDigiKey else - 6px */ }
          <ConsentLoginView setDataAfterLogout={setDataAfterLogout} />
        </div>
        {/* <DownloadCamDigiKey /> */}
      </div>
    </section>
  )
}