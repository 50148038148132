import React from "react"
import QrCodeLoginView from "../../components/QrCodeLoginView"
import LeftView from "../../components/LeftView"

// const modal = () => {
//   const close = () => {
//     alert('hehe')
//   }
//   return (
//     <div className="fixed z-10 overflow-y-auto top-0 w-full left-0">
//       <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
//         <div className="fixed inset-0 transition-opacity">
//           <div className="absolute inset-0 bg-gray-900 opacity-75" />
//         </div>
//       <div aria-labelledby="modal-headline" aria-modal="true" className="flex justify-center items-center overflow-y-auto transform transition-all duration-100 h-full p-6" role="dialog">
//         <div className="p-8 bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl w-full my-auto" id="modalInstructionContent">
//           <h3 className="mb-6 text-lg leading-6 font-medium text-gray-900">Login with QR code</h3>
//           <button className="absolute right-4 top-5 cursor-pointer text-gray-500 hover:text-gray-700 active:scale-75 duration-100" onClick={close}>
//             <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg">
//               <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round"
//                 strokeWidth="2" />
//             </svg>
//           </button>
//           <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//             <div className="flex flex-col justify-between ">
//               <div className="flex ">
//                 <div className="w-5 h-5 bg-gray-700 text-white flex items-center justify-center rounded-full flex-none mr-2 text-sm">
//                   1
//                 </div>
//                 <p className="text-sm text-gray-500">
//                   Go to <strong>CamDigiKey App</strong> and
//                   tap the button "<strong>Scan QR Code</strong>".
//                 </p>
//               </div>
//               <div className="w-44 md:w-auto mx-auto max-w-full">
//                 <img alt="" src="/static/images/how-1.png" />
//               </div>
//             </div>
//             <div className="flex flex-col justify-between">
//               <div className="flex">
//                 <div className="w-5 h-5 bg-gray-700 text-white flex items-center justify-center rounded-full flex-none mr-2 text-sm">
//                   2
//                 </div>
//                 <p className="text-sm text-gray-500">
//                   Please adjust a <strong>QR code</strong> to
//                   the center of the screen.
//                 </p>
//               </div>
//               <div className="w-44 md:w-auto mx-auto max-w-full">
//                 <img alt="" src="/static/images/how-2.png" />
//               </div>
//             </div>
//             <div className="flex flex-col justify-between">
//               <div className="flex">
//                 <div className="w-5 h-5 bg-gray-700 text-white flex items-center justify-center rounded-full flex-none mr-2 text-sm">
//                   3
//                 </div>
//                 <p className="text-sm text-gray-500">
//                   Once you check your login request, please
//                   fill your <strong>PIN code</strong>.
//                 </p>
//               </div>
//               <div className="w-44 md:w-auto mx-auto max-w-full">
//                 <img alt="" src="/static/images/how-3.png" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   )
// }

export default function QrCodeLogin() {
  return (
    <section>
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-center items-center flex-col md:flex-row px-4 py-5" style={{ minHeight: 'calc(100vh - 7rem - 6px)' }}> {/* - 206px for DownloadCamDigiKey else - 6px */ }
          <LeftView />
          <QrCodeLoginView />
        </div>
        {/* <DownloadCamDigiKey /> */}
      </div>
    </section>
  )
}